import {Title} from '@angular/platform-browser';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {TableConfigType, TableService} from '@app/shared/services/table.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EMPTY, forkJoin, Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {catchError, filter, finalize, map, take, takeUntil, tap} from 'rxjs/operators';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {NotificationsService} from '@core/services/notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {FormDialogComponent} from '../form-dialog/form-dialog.component';
import {FetcherService, Response} from '@core/services/fetcher.service';
import {NewMenuService, SlugRights} from '@core/services/menu.service';
import {TableSearchComponent} from '../table-search/table-search.component';
import {HttpErrorResponse} from '@angular/common/http';
import {TableColumnsSettingsComponent} from '../table-columns-settings/table-columns-settings.component';
import {LocalStorageService} from '@app/core/services/localStorage.service';
import {DownloadFileService} from '@core/services/download-file.service';
import {YandexMetricService} from '@app/core/services/yandex-metric.service';

const DOUBLED_SYSTEMS = ['bank', 'bank-sigma', 'bank-ma', 'sus', 'suop'];

interface FetchParams {
  limit: number;
  offset: number;
  isRenovation?: boolean;
  restricted?: boolean;
  dynamicFilter?: string;
  sortType: boolean;
  sortBy: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges, OnDestroy {
  @Input() slug: string;
  @Input() systemName: string;

  @Input() staticDataFilter: any;
  @Input() disableDeeper = false;
  @Input() subTitle: string;

  @ViewChild(TableSearchComponent) search: TableSearchComponent;
  @ViewChild(DatatableComponent) tableComponent: DatatableComponent;

  redirectSlug: string;
  slugRights: SlugRights;

  config: TableConfigType;
  restricted: boolean;
  renovation: boolean;
  isLoadingResults: boolean;
  isLoadingSheets: boolean;
  sheetsAccess: Observable<boolean>;
  disableUI: boolean;
  disableEditing: boolean;
  data = [];
  totalCount: number;

  displayedColumns: string[] = [];
  columnsConfig: [string, string, boolean][];
  selected = [];
  tableParams: FetchParams = {
    limit: 25,
    offset: 0,
    sortType: true,
    sortBy: 'name',
  };

  pendedFetching: Subscription;
  urlParams: FetchParams;

  searchParams: any;
  searchParamsShort: any;

  readonly pageSizeOptions = [10, 25, 50];
  readonly columnMinMaxWidthByType = {
    string: [150],
    text: [300],
    int: [100],
    float: [100],
    boolean: [40, 350],
    color: [100],
    date: [100, 350],
    file: [150],
    select: [250],
    action: [150, 250],
    fake: [150],
    json: [150, 350],
    default: [150],
  };
  private destroy$ = new Subject<boolean>();
  private wasEdited: number[];

  // TODO: Привести к более читаемому виду columnsConfig
  get hasColumns() {
    return this.columnsConfig.some(column => !!column[2]);
  }

  constructor(
    private title: Title,
    private $router: Router,
    private $route: ActivatedRoute,
    private $fetcher: FetcherService,
    private $table: TableService,
    private $notifications: NotificationsService,
    private $menu: NewMenuService,
    private $yandexMetricService: YandexMetricService,
    public $localStorage: LocalStorageService,
    public dialog: MatDialog,
    private elementRef: ElementRef<HTMLElement>,
    private readonly downloadFile: DownloadFileService,
  ) {
    this.isLoadingResults = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !this.config ||
      (changes.slug && changes.slug.currentValue !== changes.slug.previousValue) ||
      (changes.systemName &&
        changes.systemName.currentValue !== changes.systemName.previousValue)
    ) {
      if (this.search) {
        this.search.clear();
      }
      this.searchParams = null;
      this.searchParamsShort = null;
      this.tableParams = {
        limit: 25,
        offset: 0,
        sortType: true,
        dynamicFilter: null,
        sortBy: 'name',
      };
      this.sheetsAccess = this.$menu.getIsGS(this.slug);
      this.slugRights = this.$menu.getRights(this.slug, this.systemName);
      this.isLoadingResults = true;
      this.data = [];
      this.redirectSlug = null;
      this.config = null;
      if (!this.staticDataFilter) {
        this.title.setTitle('АвтоБОП Админ | ⏳');
      }
      this.$table
        .getConfig(
          this.slug,
          DOUBLED_SYSTEMS.indexOf(this.systemName) > -1 ? this.systemName : null,
        )
        .pipe(
          takeUntil(this.destroy$),
          catchError(error => {
            this.config = null;
            this.title.setTitle('АвтоБОП Админ');
            this.$router.navigate(['undefined'], {
              relativeTo: this.$route.parent,
            });
            return EMPTY;
          }),
        )
        .subscribe(config => {
          if (config) {
            this.config = config;
            if (!this.staticDataFilter) {
              this.title.setTitle('АвтоБОП Админ | ' + config.title);
            }
            try {
              if (config.redirectSlugName) {
                this.redirectSlug = config.redirectSlugName;
              }
              const columnsNames = this.config.columns
                .filter(x => !x.helper)
                .map(x => x.prop);
              this.displayedColumns = ['select', ...columnsNames];
              this.tableParams.sortBy = this.config.sortBy || this.displayedColumns[1];
              this.tableParams.limit = this.config.pageSize || 25;
              this.restoreConfigFromStorage();
              this.fetch(
                `OnChanges ${
                  changes.slug
                    ? [changes.slug.previousValue, changes.slug.currentValue].join(', ')
                    : [
                        changes.systemName.previousValue,
                        changes.systemName.currentValue,
                      ].join(', ')
                }`,
              );
            } catch (error) {
              this.$router.navigate(['bad-config'], {
                relativeTo: this.$route.parent,
              });
            }
          } else {
            this.config = null;
            this.$router.navigate(['undefined'], {
              relativeTo: this.$route.parent,
            });
          }
          const tableHeader = this.elementRef.nativeElement.querySelector<HTMLElement>(
            '.datatable-header .datatable-row-center',
          );
          if (tableHeader) {
            tableHeader.style.transform = 'translate3d(0px, 0px, 0px)';
          }
        });
    }
  }

  onSort({sorts}) {
    const col = this.config.columns[this.displayedColumns.indexOf(sorts[0].prop) - 1];
    this.tableParams.sortBy =
      col.type === 'select' && !Array.isArray(col.typeProps.items)
        ? col.typeProps.linkedKey || col.prop
        : col.prop;
    this.tableParams.sortType = sorts[0].dir === 'asc';
    this.tableParams.offset = 0;
    this.fetch('onSort');
  }

  onSelect({selected}) {
    if (!!selected) {
      this.selected.splice(0, this.selected.length);
      const rows = [...selected];
      this.selected.push(...rows);
      if (this.config.disableEditingOn) {
        this.disableEditing =
          rows.findIndex(row => row[this.config.disableEditingOn]) > -1;
      }
    }
  }

  unselectAll() {
    this.selected = [];
    this.disableEditing = false;
  }

  onPageChange(pageInfo) {
    if (this.tableParams.offset !== pageInfo.pageSize * pageInfo.offset) {
      this.tableParams.offset = pageInfo.pageSize * pageInfo.offset;
      this.fetch('onPageChange');
    }
  }

  onSearch(event) {
    this.tableParams.dynamicFilter = event;
    this.tableParams.offset = 0;
    this.fetch('onSearch');
    this.$yandexMetricService.reachGoal.next('trackSearchOnTable');
  }

  paramsChanged() {
    this.fetch('paramsChanged');
    this.$yandexMetricService.reachGoal.next('trackLinesOnTable');
  }

  openGoogleSheets() {
    this.isLoadingSheets = true;
    const params: any = this.config.isRestricted
      ? {restricted: this.tableParams.restricted, ...this.urlParams}
      : {...this.urlParams};

    if (this.config.isRenovation != null) {
      params.isRenovation = this.renovation;
    }

    params.limit = this.totalCount;

    this.download(params);
  }

  deleteRows() {
    this.isLoadingResults = true;
    this.disableUI = true;
    const observables = this.selected.map(s =>
      this.$fetcher.deleteBySlug(
        this.redirectSlug || this.slug,
        s.id,
        this.config.apiPrefix,
        this.systemName,
      ),
    );

    forkJoin(observables)
      .pipe(
        takeUntil(this.destroy$),
        catchError((response: HttpErrorResponse) => {
          this.isLoadingResults = false;
          throwError(
            this.$notifications.showHttpError(response, 'DELETE', this.config.title),
          );
          return of(null);
        }),
      )
      .subscribe(res => {
        if (res) {
          this.onRowDelete();
          this.fetch('onRowDelete');
        }
      });
  }

  openDialog(edit: boolean, copy?: boolean) {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      autoFocus: false,
      maxWidth: null,
      data: {
        config: this.config,
        rows: edit || copy ? this.selected : [],
        slug: this.redirectSlug || this.slug,
        systemName: this.systemName,
        staticDataFilter: this.staticDataFilter || {},
        copy,
      },
      panelClass: ['scrollable-dialog', 'form-dialog'],
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        map(changed => (changed ? changed?.data[0] : EMPTY)),
      )
      .subscribe(changed => {
        if (!!changed) {
          this.wasEdited = changed.id;
          this.onDialogClose();
          this.fetch('afterDialogClosed');
        }
      });
  }

  openTableColumnsSettings() {
    const dialogRef = this.dialog.open(TableColumnsSettingsComponent, {
      autoFocus: false,
      maxWidth: null,
      data: {
        slug: this.redirectSlug || this.slug,
        config: this.config,
      },
      panelClass: ['scrollable-dialog', 'settings-dialog'],
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(config => {
        if (!!config) {
          this.onDialogClose();
          this.columnsConfig = config;
        }
      });
  }

  openAdvancedSearchSettings() {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      autoFocus: false,
      maxWidth: null,
      data: {
        config: Object.assign({}, this.config, {
          columns: this.config.searchColumns,
        }),
        slug: this.redirectSlug || this.slug,
        rows: [this.searchParams],
        isSearch: true,
      },
      panelClass: ['scrollable-dialog', 'form-dialog'],
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        take(1),
        filter(res => res && res.fullValues && res.shortValues),
      )
      .subscribe(({fullValues, shortValues}) => {
        Object.keys(fullValues).forEach(key => {
          if (
            fullValues[key] === null ||
            fullValues[key] === undefined ||
            (Array.isArray(fullValues[key]) && fullValues[key].length === 0)
          ) {
            delete fullValues[key];
          }
        });

        Object.keys(shortValues).forEach(key => {
          if (
            shortValues[key] === null ||
            shortValues[key] === undefined ||
            (Array.isArray(shortValues[key]) && shortValues[key].length === 0)
          ) {
            delete shortValues[key];
          }
        });

        const keysCount = Object.keys(fullValues).length;
        this.searchParams = keysCount > 0 ? fullValues : null;
        this.searchParamsShort = shortValues;

        this.onDialogClose();
        this.fetch('afterSearchClosed');
      });
  }

  private fetch(from?: string) {
    this.data = [];
    this.isLoadingResults = true;
    if (this.pendedFetching) {
      this.pendedFetching.unsubscribe();
    }
    this.urlParams = Object.assign(
      {},
      this.staticDataFilter || {},
      this.tableParams,
      this.searchParamsShort,
    );

    this.pendedFetching = this.$fetcher
      .getBySlug(
        this.redirectSlug || this.slug,
        this.urlParams,
        this.config.apiPrefix,
        this.systemName,
      )
      .pipe(
        takeUntil(this.destroy$),
        map((res: Response<any[]>) => {
          this.totalCount = res.meta ? res.meta.totalCount : res.data.length;
          return res.data.map(el =>
            el.id === this.wasEdited
              ? {...el, wasEdited: true}
              : {...el, wasEdited: false},
          );
        }),
        catchError((response: HttpErrorResponse) => {
          throwError(
            this.$notifications.showHttpError(response, 'GET', this.config.title),
          );
          return of([]);
        }),
        finalize(() => (this.isLoadingResults = false)),
      )
      .subscribe(data => {
        this.data = data;

        this.unselectAll();
        const tableHeader = this.elementRef.nativeElement.querySelector<HTMLElement>(
          '.datatable-header .datatable-row-center',
        );
        const tableBody =
          this.elementRef.nativeElement.querySelector<HTMLElement>('.datatable-body');
        if (tableHeader) {
          const tableHeaderOffset = tableHeader.style.transform.match(/-?(\d*\.?\d+)px/);
          tableHeader.style.transform = `translate3d(${tableHeaderOffset[0]}, 0px, 0px)`;
          tableBody.style.opacity = '0';
          setTimeout(() => {
            tableBody.scrollTo(parseFloat(tableHeaderOffset[1]), 0);
            tableBody.style.opacity = '1';
          }, 1);
        }
      });
  }

  private restoreConfigFromStorage() {
    const slugName = this.redirectSlug || this.slug;
    const configJSON = this.$localStorage.getItem(`${slugName}Config`);
    if (configJSON) {
      try {
        this.columnsConfig = JSON.parse(configJSON);
      } catch {
        this.columnsConfig = this.createConfig();
      }
    } else {
      this.columnsConfig = this.createConfig();
    }
  }

  private createConfig(): [string, string, boolean][] {
    return (this.columnsConfig = this.config.columns.reduce((acc, col) => {
      acc.push([col.prop, col.label, true]);
      return acc;
    }, []));
  }

  protected onDialogClose() {}
  protected onRowDelete() {}

  private download(params): void {
    this.downloadFile
      .downloadExcel(
        `backoffice/v2/${this.redirectSlug || this.slug}googlesheets`,
        this.slug,
        params,
      )
      .pipe(
        finalize(() => {
          this.isLoadingSheets = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.pendedFetching) {
      this.pendedFetching.unsubscribe();
    }

    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
